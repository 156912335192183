<template>
  <b-container
    v-if="currentTabIndex === 1"
    class="px-1 py-2"
    fluid
  >
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="6"
        order="5"
        order-md="1"
      >
        <b-form-group
          label="Search"
          label-for="filter_search"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_search"
            v-model="tableVisit.filter"
            type="text"
            size="sm"
            placeholder="search here"
            debounce="1000"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        order="2"
      >
        <b-form-group
          label="Status"
          label-for="filter_status"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-select
            v-model="filter.filter_status"
            size="sm"
            :options="options.filter_statuses"
            @change="onFilterChanged"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        order="3"
      >
        <b-form-group
          label="Visited From"
          label-for="filter_date_from"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_date_from"
            v-model="filter.date_from"
            type="date"
            size="sm"
            @change="onFilterChanged"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="2"
        order="4"
      >
        <b-form-group
          label="Visited To"
          label-for="filter_date_to"
          label-class="font-weight-bolder"
          class="mb-2"
        >
          <b-input
            id="filter_date_to"
            v-model="filter.date_to"
            type="date"
            size="sm"
            @change="onFilterChanged"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        order="6"
      >
        <b-form-checkbox
          id="opportunity_only"
          v-model="filter.opportunity_only"
          name="opportunity_only"
          value="1"
          unchecked-value="0"
          switch
          @change="onFilterChanged"
        >
          <strong>Show Sales Opportunity Only?</strong>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >

        <b-table
          ref="table"
          small
          hover
          responsive
          show-empty
          class="mt-2"
          :stacked="isMobile()"
          :per-page="tableVisit.perPage"
          :current-page="tableVisit.currentPage"
          :items="tableVisitProvider"
          :fields="tableVisit.fields"
          :sort-by.sync="tableVisit.sortBy"
          :sort-desc.sync="tableVisit.sortDesc"
          :sort-direction="tableVisit.sortDirection"
          :filter="tableVisit.filter"
          :filter-included-fields="tableVisit.filterOn"
          :busy="tableVisit.busy"
        >
          <template #cell(index)="data">
            {{ tableVisit.currentPage * tableVisit.perPage - tableVisit.perPage + (data.index + 1) }}
          </template>

          <template #cell(sales_opportunity)="data">
            {{ Number(data.item.sales_opportunity) == 1 ? "Yes" : "No" }}
          </template>

          <template #cell(successful)="data">
            {{ Number(data.item.is_successful) == 1 ? "Yes" : "No" }}
          </template>

          <template #cell(booked)="data">
            {{ Number(data.item.successful_booking) == 1 ? "Yes" : "No" }}
          </template>

          <template #cell()="data">
            <div class="text-nowrap">
              {{ data.value }}
            </div>
          </template>

          <template #cell(action)="row">
            <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
              <b-button
                size="sm"
                @click="onShowDetailForm(row.item)"
              >
                View
              </b-button>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center py-5">
              <b-icon
                icon="stopwatch"
                font-scale="5"
                animation="cylon"
              />
              <p class="h3 py-2">
                <strong>Loading . . .</strong>
              </p>
            </div>
          </template>

        </b-table>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="w-100 w-sm-25 mb-2 sm-mb-2">
          <b-select
            v-model="tableVisit.perPage"
            :options="tableVisit.pageOptions"
            size="sm"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="tableVisit.currentPage"
          :total-rows="tableVisit.totalRows"
          :per-page="tableVisit.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-view-detail"
      title="View Details"
      size="lg"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
    >
      <b-tabs>
        <b-tab
          title="Request Details"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Request Date</label>
                <b-input
                  :value="dateTimeFormatter(selected.visit_request?.created_at)"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Number</label>
                <b-input
                  :value="selected.visit_request?.ticket_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Ticket Category</label>
                <b-input
                  :value="selected.visit_request?.ticket_category_name"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Due Date</label>
                <b-input
                  :value="selected.visit_request?.due_date"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Customer Name</label>
                <b-input
                  :value="`${selected.visit_request?.customer?.first_name} ${selected.visit_request?.customer?.last_name}`"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Mobile Number</label>
                <b-input
                  :value="selected.visit_request?.customer?.mobile_number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label>Status</label>
                <b-input
                  :value="selected.visit_request?.status"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label>Remarks</label>
                <b-textarea
                  :value="selected.visit_request?.remarks"
                  rows="10"
                  max-rows="10"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Visitation Logs"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-table
                ref="visitationLogTable"
                hover
                responsive
                show-empty
                class="mt-2"
                :stacked="isMobile()"
                :per-page="tableVisitationLog.perPage"
                :current-page="tableVisitationLog.currentPage"
                :items="tableVisitationLogProvider"
                :fields="tableVisitationLog.fields"
                :sort-by.sync="tableVisitationLog.sortBy"
                :sort-desc.sync="tableVisitationLog.sortDesc"
                :sort-direction="tableVisitationLog.sortDirection"
                :filter="tableVisitationLog.filter"
                :filter-included-fields="tableVisitationLog.filterOn"
                :busy="tableVisitationLog.busy"
              >

                <template #cell(index)="data">
                  {{ tableVisitationLog.currentPage * tableVisitationLog.perPage - tableVisitationLog.perPage + (data.index + 1) }}
                </template>

                <template #cell()="data">
                  <div class="text-nowrap">
                    {{ data.value }}
                  </div>
                </template>

                <template #cell(action)="row">
                  <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                    <b-button
                      size="sm"
                      @click="onViewVisitationLog(row.item)"
                    >
                      View
                    </b-button>
                  </div>
                </template>

              </b-table>
            </b-col>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableVisitationLog.perPage"
                  :options="tableVisitationLog.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableVisitationLog.currentPage"
                :total-rows="tableVisitationLog.totalRows"
                :per-page="tableVisitationLog.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-visit-request-histories"
      title="Visitation Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Visited At</label>
            <b-input
              :value="dateFormatter(selected.log?.visitation_date)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>LGA/RFO</label>
            <b-input
              :value="selected.log?.user_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Purpose</label>
            <b-input
              :value="selected.log?.purpose_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <p class="mb-1">
            Uploaded Photos
          </p>
          <b-table
            hover
            small
            striped
            responsive
            show-empty
            :stacked="isMobile()"
            :fields="tableVisitationLogAttachments.fields"
            :items="selected.log?.visitation_log_attachments"
          >
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(action)="row">
              <div class="text-md-nowrap d-flex flex-column justify-content-start flex-md-row justify-content-md-center">
                <b-button
                  size="sm"
                  class="mr-1"
                  @click="onPreviewVisitationLogAttachment(row.item)"
                >
                  Preview
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>

      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="moda-cancel-request"
      title="Cancel Request"
      scrollable
      no-close-on-backdrop
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-row>
            <b-col cols="12">
              <ValidationProvider
                #default="{ errors }"
                name="remarks"
                vid="remarks"
                rules="required|max:1000"
              >
                <div class="form-group">
                  <label for="remarks">Remarks</label>
                  <b-textarea
                    id="remarks"
                    v-model="cancelForm.remarks"
                    :state="errors.length > 0 ? false : null"
                    rows="10"
                    max-rows="10"
                    :disabled="state.busy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </div>
              </ValidationProvider>
            </b-col>

          </b-row>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          class="mr-1"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-log-attachment"
      :title="ModalAttachmentTitle"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-img
        :src="visitationLogAttachment.preview_photo_path"
        fluid-grow
      />
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>s

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, UserReportService, SharedLogService } from '@/services'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'
import url from '@/mixins/url'

export default {
  name: 'UserVisitHistories',

  middleware: ['auth', 'user'],

  mixins: [formatter, misc, url],

  props: {
    currentTabIndex: {
      type: Number,
      default: () => 1
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        filter_status: 'All',
        opportunity_only: 0
      },
      selected: {
        visit_request: null,
        log: null
      },
      list: {
        purposes: []
      },
      options: {
        filter_statuses: [
          { text: 'All', value: 'All' },
          { text: 'Closed', value: 'Closed' },
          { text: 'Cancelled', value: 'Cancelled' }
        ]
      },
      cancelForm: {
        id: 0,
        remarks: ''
      },
      visitationLogAttachment: {
        preview_photo_name: null,
        preview_photo_path: null
      },
      tableVisit: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 20, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { mobile: 2, key: 'status', sortable: true },
          { mobile: 3, key: 'ticket_number' },
          { mobile: 4, key: 'ticket_category_name', label: 'ticket category' },
          { mobile: 5, key: 'due_date' },
          { mobile: 6, key: 'sales_opportunity' },
          { mobile: 7, key: 'booked' },
          { mobile: 8, key: 'successful' },
          { mobile: 9, key: 'customer.first_name', label: 'First Name' },
          { mobile: 10, key: 'customer.last_name', label: 'Last Name' },
          { mobile: 11, key: 'customer.mobile_number', label: 'Mobile Number' },
          { mobile: 12, key: 'customer.province.province_name', label: 'Province' },
          { mobile: 13, key: 'customer.municipality.municipality_name', label: 'Municipality' },
          { mobile: 14, key: 'customer.barangay.barangay_name', label: 'Barangay' },
          { mobile: 15, key: 'lead_name', label: 'Assigned To' },
          { mobile: 16, key: 'agent_name', label: 'Requested By' },
          { mobile: 17, key: 'closed_at', label: 'Closed At' },
          { mobile: 18, key: 'cancelled_at', label: 'Cancelled At' },
          { mobile: 19, key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      },
      tableVisitationLog: {
        busy: false,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 6, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'visitation_date', formatter: this.dateFormatter },
          { mobile: 2, key: 'user_name', label: 'lga/rfo' },
          { mobile: 3, key: 'purpose_name', label: 'purpose' },
          { mobile: 4, key: 'created_at', formatter: this.dateTimeFormatter },
          { mobile: 5, key: 'updated_at', formatter: this.dateTimeFormatter }
        ].sort(this.scaleOnMobile)
      },
      tableVisitationLogAttachments: {
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 2, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'file_original_name', label: 'file name' }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  computed: {
    ModalAttachmentTitle () {
      return this.visitationLogAttachment.preview_photo_name
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async tableVisitProvider (ctx) {
      this.tableVisit.busy = true
      return await UserReportService.getVisitHistories(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          filter_from: this.filter.date_from,
          filter_to: this.filter.date_to,
          filter_status: this.filter.filter_status,
          opportunity_only: this.filter.opportunity_only
        })
      ).then(({ data }) => {
        this.tableVisit.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableVisit.busy = false
      })
    },

    async tableVisitationLogProvider (ctx) {
      this.tableVisitationLog.busy = true
      return await SharedLogService.getVisitationLogs(
        this.objectToUrl({
          page: ctx.currentPage,
          per_page: ctx.perPage,
          sort: ctx.sortBy,
          sort_desc: ctx.sortDesc,
          filter_text: ctx.filter,
          customer_id: this.selected.visit_request.customer?.id,
          request_id: this.selected.visit_request.id
        })
      ).then(({ data }) => {
        this.tableVisitationLog.totalRows = data.total_rows
        return data.items
      }).catch(() => []).finally(() => {
        this.tableVisitationLog.busy = false
      })
    },

    onShowDetailForm (current) {
      this.selected.visit_request = { ...current }
      this.$bvModal.show('modal-view-detail')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          const title = 'Do you really want cancel request?'
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        UserReportService.put(this.cancelForm).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('moda-cancel-request')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onViewVisitationLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-visit-request-histories')
    },

    onPreviewVisitationLogAttachment (attachment) {
      this.visitationLogAttachment.preview_photo_name = attachment.file_original_name
      this.visitationLogAttachment.preview_photo_path = this.getImageUrl(`${this.selected.log?.attachment_path}/${attachment.file_hash_name}`)
      this.$bvModal.show('modal-preview-log-attachment')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
  <style>
  #modal-preview-log-attachment .modal-xl {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }
  #modal-preview-log-attachment .modal-content {
    min-height: 100vh;
  }
  </style>
